<template>
  <div>
    <AtomLogo />
    <MoleculeCard size="md">
      <MoleculeForm
        :validator="$v"
        :loading="submitLoading"
        :disabled="submitLoading"
        @submit="submitHandler"
      >
        <h2 class="card-title text-center mb-4">{{ $t('pages.register.title') }}</h2>
        <div class="row">
          <div class="col-12 col-lg-6">
            <AtomInput
              v-model.trim="form.surname"
              label="surname"
              class="mb-3"
            />
          </div>
          <div class="col-12 col-lg-6">
            <AtomInput
              v-model.trim="form.name"
              label="name"
              class="mb-3"
            />
          </div>
          <div class="col-12">
            <AtomInput
              v-model.trim="form.patronymic"
              label="patronymic"
              class="mb-3"
            />
          </div>
          <div class="col-12 col-lg-6">
            <AtomInput
              v-model.trim="form.phone"
              label="phone"
              type="phone"
              class="mb-3"
            />
          </div>
          <div class="col-12 col-lg-6">
            <AtomInput
              v-model.trim="form.email"
              label="email"
              class="mb-3"
            />
          </div>
          <div class="col-12 col-lg-6">
            <AtomInput
              v-model.trim="form.password"
              label="password"
              type="password"
              class="mb-3"
            />
          </div>
          <div class="col-12 col-lg-6">
            <AtomInput
              v-model.trim="form.passwordConfirm"
              label="password.confirm"
              type="password"
              class="mb-3"
            />
          </div>
        </div>
        <AtomChoose
          v-model="form.agreement"
          label="agreement"
          class="mb-2"
          direction="ltr"
        >
          <i18n path="agreement.agree">
            <template #action>
              <router-link
                to="/agreement"
                place="action"
              >
                {{ $t('agreement.action') }}
              </router-link>
            </template>
          </i18n>
        </AtomChoose>
        <div class="form-footer">
          <AtomButton
            type="submit"
            color="primary"
            class="w-100"
            :disabled="$v.$anyError"
          >
            {{ $t('action.register') }}
          </AtomButton>
        </div>
      </MoleculeForm>
    </MoleculeCard>
    <div class="text-center text-muted mt-3">
      {{ $t('login.already') }}
      <router-link
        to="/login"
        tabindex="-1"
      >
        {{ $t('action.login') }}
      </router-link>
    </div>
    <div class="text-center text-muted mt-3">
      {{ $t('support.need.question') }}
      <a
        :href="$telegramLink"
        target="_blank"
        tabindex="-1"
      >
        {{ $t('support.need.answer') }}
      </a>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs, minLength } from 'vuelidate/lib/validators'
import { checked } from '@/validators'
import { mapActions } from 'vuex'

import MoleculeCard from '@/components/MoleculeCard'
import MoleculeForm from '@/components/MoleculeForm'
import AtomInput from '@/components/AtomInput'
import AtomChoose from '@/components/AtomChoose'
import AtomButton from '@/components/AtomButton'
import AtomLogo from '@/components/AtomLogo'

export default {
  title: vm => vm.$t('pages.register.title'),

  components: {
    MoleculeCard,
    MoleculeForm,
    AtomInput,
    AtomChoose,
    AtomButton,
    AtomLogo,
  },

  data: () => ({
    form: {
      surname: '',
      name: '',
      patronymic: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirm: '',
      agreement: false,
    },

    submitLoading: false,
  }),

  validations: {
    form: {
      surname: { required, minLength: minLength(3) },
      name: { required, minLength: minLength(3) },
      phone: { required, minLength: minLength(18) },
      email: { email, required },
      password: { required, minLength: minLength(6) },
      passwordConfirm: { required, sameAsPassword: sameAs(function () { return this.form.password }) },
      agreement: { checked },
    },
  },

  methods: {
    ...mapActions(['register']),

    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.submitLoading = true
        await this.register(this.form)
        this.$router.push({ name: 'Home', params: { register: true } })
        this.$notify({
          title: this.$t('action.welcome'),
          text: this.$t('register.success.message'),
          type: 'success',
        })
      } catch {} finally {
        this.submitLoading = false
      }
    },
  },
}
</script>
